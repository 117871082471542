<template>
    <div class="count-container">
      <div class="txt-color title title-flex">
        {{ title }}
      <slot></slot>
      </div>
      <div class="txt-color time">{{ timeValue[0] }} ~ {{ timeValue[1] }}</div>
      <div class="count">
        <div class="count-flex">
          <div>
            <countTo
              :startVal="Number(startVal)"
              :endVal="Number(toDayData)"
              :duration="duration"
            ></countTo>
            <span class="suffix txt-color">{{ suffix }}</span>
          </div>
          <div class="txt-color compare">
            <!-- <div>环比<span class="up"></span>1.92%</div>
            <div>同比<span class="down"></span>2.22%</div> -->
  
            <div>
              环比2<span
                class=""
                :class="
                  Math.sign(chainRatio) && Math.sign(chainRatio) < 0
                    ? 'down'
                    : 'up'
                "
              ></span
              >{{ Math.abs(chainRatio) }}%
            </div>
            <div>
              同比<span
                class=""
                :class="
                  Math.sign(yearOnYear) && Math.sign(yearOnYear) < 0
                    ? 'down'
                    : 'up'
                "
              ></span
              >{{ Math.abs(yearOnYear) }}%
            </div>
          </div>
        </div>
        <div class="count-total">
          <div>
            合计2<span>{{ totalNum }}</span>
          </div>
          <div>
            均值2<span>{{ avg }}</span>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  import countTo from "vue-count-to";
  import { format, subDays } from "date-fns";
  export default {
    name: "count-to-num",
    components: { countTo },
    props: {
      title: {
        type: String,
        default: "数据统计"
      },
      time: {
        type: Array,
        default: () => {
          return [
            format(subDays(new Date(), 7), "yyyy-MM-dd"),
            format(new Date(), "yyyy-MM-dd")
          ];
        }
      },
      avg: {
        default: 0
      },
      totalNum: {
        default: 0
      },
      startVal: {
        type: Number,
        default: 0
      },
      endVal: {
        type: Number,
        default: 1000
      },
      suffix: {
        type: String,
        default: ""
      },
      source: {},
      chainRatio: {},
      yearOnYear: {},
      toDayData: {}
    },
    data() {
      return {
        duration: 3000
      };
    },
    computed: {
      timeValue() {
        let _array = [];
        if (this.time[0] instanceof Date && this.time[1] instanceof Date) {
          _array[0] = format(this.time[0], "yyyy-MM-dd");
          _array[1] = format(this.time[1], "yyyy-MM-dd");
        } else {
          _array[0] = format(new Date(this.time[0]), "yyyy-MM-dd");
          _array[1] = format(new Date(this.time[1]), "yyyy-MM-dd");
        }
        return _array;
      }
    },
    created() {},
    mounted() {},
    methods: {}
  };
  </script>
  <style scoped lang="scss">
  .count-container {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 150px;
  }
  .txt-color {
    color: #6a788a;
  }
  .title {
    font-size: 16px;
  }
  .title-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .time {
    font-size: 14px;
  }
  .count {
    font-size: 32px;
    display: flex;
    height: 80px;
  }
  .count-flex {
    display: flex;
    align-items: center;
    flex: 0 0 70%;
  }
  .count-total {
    font-size: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    box-sizing: border-box;
    padding-left: 20px;
    border-left: 1px solid #eeeeee;
    color: #6a788a;
    span {
      color: #333;
      font-weight: bold;
      margin: 0 5px;
    }
  }
  .suffix {
    font-size: 16px;
  }
  .compare {
    display: flex;
    flex-direction: column;
    margin-left: 15px;
    font-size: 16px;
  }
  
  .compare div {
    display: flex;
    align-items: center;
    font-size: 16px;
  }
  .compare div {
    margin-right: 10px;
  }
  .compare span {
    margin-left: 5px;
    margin-right: 2px;
  }
  .up {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 10px solid #2cca93;
    font-size: 0;
    line-height: 0;
  }
  .down {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 10px solid #fd6771;
    font-size: 0;
    line-height: 0;
  }
  </style>
  